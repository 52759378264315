import React, { useEffect, useState } from "react";
import "./styles.css";
import Layout from "../Layout/Content";
import Logo from "../../../image/Logo.png";
import RegisterImg from "../../../image/register-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import axios from "axios";
import {
  DeodAbi,
  DeodAddress,
  StakeAbi,
  StakeAddress,
  StakeAddressTwo,
  StakeV2Balance,
} from "../Abi/Content";
import Marquee from "react-fast-marquee";
// NEW

const Content = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [regUsers, setregUsers] = useState(false);
  const [auth, setAuth] = useAuth();
  const [refferal, setRefferal] = useState("");
  const location = useLocation();


  const [totalDeodStack, setTotaldeodStack] = useState();
  const handleIsRegisterApi = async (address) => {
    try {
      const resp = await axios.post(`/users/login`, {
        accountAddress: address,
      });
      if (resp.status === 200) {
        toast.success("Account already Registered , Please Login !")
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        const isAdmin = ["0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb"];
        var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
        setAuth({
          ...auth,
          user: {
            address: address,
            referralAddress: refferal,
            role: role,
          },
        });
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            user: {
              address: address,
              referralAddress: refferal,
              role: role,
            },
          })
        );
        navigate("/register");
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleIsRegisterApiPro = async (address) => {
    try {
      const resp = await axios.post(`/nefi-pro-users/login`, {
        accountAddress: address,
      });
      if (resp.status === 200) {
        toast.success("Account already Registered , Please Login !")
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        const isAdmin = ["0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb"];
        var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
        setAuth({
          ...auth,
          user: {
            address: address,
            referralAddress: refferal,
            role: role,
          },
        });
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            user: {
              address: address,
              referralAddress: refferal,
              role: role,
            },
          })
        );
        navigate("/register");
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleIsregister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const message = [
        "Welcome to NexDefi !",
        "Please sign this message to verify your identity.",
        "Please sign in!",
      ].join("\n\n");
      await signer.signMessage(message);
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      await handleIsRegisterApiPro(address);
    } catch (error) {
      console.log("wallet loagin Error", error);
    }
    setLoading(false);
  };
// const handleIsregister = async () => {
//   alert('Server under maintenence , Please try again later !')
// }
// const handleLogin = async () => {
  
// }
const handleIsConnectApi = async (address) => {
  try {
    const resp = await axios.post(`/users/login`, {
      accountAddress: address,
    });
    if (resp.status === 200) {
      if (resp?.data?.status === true) {
        const message = resp?.data?.message;
        const { accountAddress, sponserAddress, dateOfRegistration, userId } =
          resp?.data?.data;
        if (message === "Login successful") {
          const isAdmin = [
            // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
            "0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb",
          ];
          var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
          setAuth({
            ...auth,
            user: {
              userId,
              address: accountAddress,
              referralAddress: sponserAddress,
              role: role,
              token: resp?.data?.token,
              dateOfRegistration,
            },
          });
          sessionStorage.setItem(
            "auth",
            JSON.stringify({
              user: {
                userId,
                address: accountAddress,
                referralAddress: sponserAddress,
                role: role,
                token: resp?.data?.data?.token,
                dateOfRegistration,
              },
            })
          );
          sessionStorage.setItem('isAuthenticated', 'true');
          sessionStorage.setItem("myToggled", 0);
          sessionStorage.setItem('token',resp?.data?.data?.token,)

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp?.data?.token}`;
          let data = sessionStorage.getItem("auth");
          data = JSON.parse(data);
          const admin = data?.user?.role;
          if (admin === 1) {
            navigate("/admin-dashboard");
          } else {
            navigate("/contract1");
          }
        }
      }
    } else {
      toast.error("Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      var err_400 = error?.response?.data?.message;
      toast.error(err_400);
    } else if (error.response.status === 401) {
      const err_401 = error?.response?.data?.message;
      toast.error(err_401);
    } else if (error.response.status === 404) {
      const err_404 = error?.response?.data?.message;
      if (err_404 =="Invalid Account Address!"){
        toast.error('Invalid Account Address ,Please Register !');

      }else{
        toast.error(err_404);
      }
    } else if (error.response.status === 500) {
      const err_500 = error?.response?.data?.message;
      toast.error(err_500);
    } else {
      console.log("Error in Register", error);
      toast.error("something went wrong");
    }
  }
};
const handleIsConnectApiPro = async (address) => {
    
  try {
    const resp = await axios.post(`/users/login`, {
      accountAddress: address,
    });
    if (resp.status === 200) {
      if (resp?.data?.status === true) {
        const message = resp?.data?.message;
        const { accountAddress, sponserAddress, dateOfRegistration, userId } =
          resp?.data?.data;
        if (message === "Login successful") {
          const isAdmin = [
            // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
            "0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb",
          ];
          var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
          setAuth({
            ...auth,
            user: {
              userId,
              address: accountAddress,
              referralAddress: sponserAddress,
              role: role,
              token2: resp?.data?.token,
              dateOfRegistration,
            },
          });
          sessionStorage.setItem(
            "auth",
            JSON.stringify({
              user: {
                userId,
                address: accountAddress,
                referralAddress: sponserAddress,
                role: role,
                token2: resp?.data?.data?.token,
                dateOfRegistration,
              },
            })
          );
          sessionStorage.setItem('isAuthenticated', 'true');
          sessionStorage.setItem("myToggled2", 0);
          sessionStorage.setItem('token',resp?.data?.data?.token,)
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp?.data?.token}`;
          let data = sessionStorage.getItem("auth");
          data = JSON.parse(data);
          const admin = data?.user?.role;
          if (admin === 1) {
            navigate("/admin-dashboard");
          } else {
              try {
                const resp2 = await axios.post(`/nefi-pro-users/login`, {
                  accountAddress: address,
                });
                if (resp2.status === 200) {
                  sessionStorage.setItem('token2',resp2?.data?.data?.token,)
                }
              } catch (error) {
                
              }

            navigate("/contract1");
          }
        }
      }
    } else {
      toast.error("Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      var err_400 = error?.response?.data?.message;
      toast.error(err_400);
    } else if (error.response.status === 401) {
      const err_401 = error?.response?.data?.message;
      toast.error(err_401);
    } else if (error.response.status === 404) {
      const err_404 = error?.response?.data?.message;
      if (err_404 =="Invalid Account Address!"){
        toast.error('Invalid Account Address ,Please Register !');

      }else{
        try {
          const resp = await axios.post(`/nefi-pro-users/login`, {
            accountAddress: address,
          });
          if (resp.status === 200) {
            if (resp?.data?.status === true) {
              const message = resp?.data?.message;
              const { accountAddress, sponserAddress, dateOfRegistration, userId } =
                resp?.data?.data;
              if (message === "Login successful") {
                const isAdmin = [
                  // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
                  "0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb",
                ];
                var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
                setAuth({
                  ...auth,
                  user: {
                    userId,
                    address: accountAddress,
                    referralAddress: sponserAddress,
                    role: role,
                    token2: resp?.data?.token,
                    dateOfRegistration,
                  },
                });
                sessionStorage.setItem(
                  "auth",
                  JSON.stringify({
                    user: {
                      userId,
                      address: accountAddress,
                      referralAddress: sponserAddress,
                      role: role,
                      token2: resp?.data?.data?.token,
                      dateOfRegistration,
                    },
                  })
                );
                sessionStorage.setItem('isAuthenticated', 'true');
                sessionStorage.setItem("myToggled", 0);
                sessionStorage.setItem('token2',resp?.data?.data?.token,)
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${resp?.data?.token}`;
                let data = sessionStorage.getItem("auth");
                data = JSON.parse(data);
                const admin = data?.user?.role;
                if (admin === 1) {
                  navigate("/admin-dashboard");
                } else {
                  navigate("/contract2");
                }
              }
            }
          } else {
            toast.error("Error", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 404) {
            const err_404 = error?.response?.data?.message;
            if (err_404 =="Invalid Account Address!"){
              toast.error('Invalid Account Address ,Please Register !');
    
            }else{
              toast.error(err_404);
            }
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in Register", error);
            toast.error("something went wrong");
          }
        }
      }
    } else if (error.response.status === 500) {
      const err_500 = error?.response?.data?.message;
      toast.error(err_500);
    } else {
      console.log("Error in Register", error);
      toast.error("something went wrong");
    }
  }
};
const handleLogin = async (e) => {
  e.preventDefault();
  setLoading(true);
  try {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      window.alert("No ethereum browser !checkout metamask");
    }
    if (provider) {
      if (provider !== window.ethereum) {
        console.error(
          "Not window.ethereum.Do you have multiple wallets installed"
        );
      }
      await provider.request({
        method: "eth_requestAccounts",
      });
    }
    const newProvider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = newProvider.getSigner();
    const message = [
      "Welcome to the Decentrawood Lock!",
      "Please sign this message to verify your identity.",
      "Please sign in!",
    ].join("\n\n");
    await signer.signMessage(message);
    const currentaccount = await signer.getAddress();
    const address = currentaccount.toLowerCase();
    // setAddress(address);
    await handleIsConnectApiPro(address);
  } catch (error) {
    console.log("wallet loagin Error", error);
  }
  setLoading(false);
};
const getRegisteredUsers = async () => {
  
  const resp = await axios('/users/total')
  console.log(resp.data.totalUser)
  setregUsers(resp.data.totalUser)
}
  useEffect(() => {
    if (location?.search !== "") {
      var dataSplit = location?.search.split("/");
      dataSplit = dataSplit[0].split("?")[1];
      setRefferal(dataSplit);
    }
  }, [location?.pathname]);

  useEffect(() => {
    const jsonRpcUrl = "https://polygon-rpc.com";
    const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);

    const handleGetTotalDeodStack = async () => {
      try {
        const DeodContract = new ethers.Contract(
          DeodAddress,
          DeodAbi,
          provider
        );
        let totalstackBalance = await DeodContract.balanceOf(StakeV2Balance);
        totalstackBalance = parseFloat(totalstackBalance) / 10 ** 18;
        totalstackBalance = totalstackBalance.toFixed(2);
        setTotaldeodStack(totalstackBalance);
      } catch (error) {
        console.log("totalstack error", error);
      }
    };

    handleGetTotalDeodStack();
    // getRegisteredUsers()
  }, []);
  // console.log("totaldeodStack", totalDeodStack);
  useEffect(() => {
    // Extracting the query parameters
    const queryParams = new URLSearchParams(location.search);

    // Getting the value of a specific parameter (e.g., 'id')
    const id = queryParams.get('referral');

    if (id) {
      // Storing the parameter value in sessionStorage
      sessionStorage.setItem('referral', id);
      setRefferal(id)
    }
  }, [location.search]);
  return (
    <Layout>
      <div className="home-bg">
        <div className="container">
          {/* <div className="row mt-4">
           <div className="d-flex justify-content-center">
           <div className="mt-4 text-white p-3  " style={{borderRadius:'10px',fontWeight:'bold',background:'#093adb'}}>
            Total Users on the Platform: {regUsers}   
              </div>
           </div>
          </div> */}
          <div className="row">
            <div className="col-12 text-center mt-5">
              {/* <div className="deod-balance">
                <div className="row">
                  <div className="col-3 walled-deod-iconclr">
                    <div className="deod-walled-icon">
                      <i class="bi bi-wallet2"></i>
                    </div>
                  </div>
                  <div className="col-9">
                    <p>Total deod amount staked</p>
                    <p> {totalDeodStack}</p>
                  </div>
                </div>
              </div> */}
              <div className="register-box">
                <h2 className="text-white" style={{fontWeight:'bolder'}}>
                  NEX<span className="cus-span" >DEFI</span>
                </h2>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="register-img">
                      <img
                        src={RegisterImg}
                        className="img-fluid"
                        alt="register"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    {/* <div className="rglogo-box">
                      <img src={Logo} alt="rglogo" />
                    </div> */}
                    <form onSubmit={handleIsregister}>
                      <button className="register-btn" type="submit">
                        REGISTER
                      </button>
                    </form>
                      <div className="text-white mt-3">
                        Already Registered ? <span className="cus-span hover-text" onClick={handleLogin}>Connect Wallet</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </Layout>
  );
};

export default Content;
