import React, { useEffect, useState } from "react";
import Loading from "../LoaderPageSection/Loading";
import Layout from "../Layout/Content";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAuth } from "../../context/auth";
import { FaTimes } from "react-icons/fa";
import { nefiToken, nefiTokenABI } from "../Abi/Content";
import { ethers } from "ethers";

const Content = ({ children ,content}) => {
  const location = useLocation();
  const token = sessionStorage.getItem('token')
  const sideLinks = token ? [`contract2`,`contract1`]: [`contract2`]
  const isActive = (path) => {
    return location.pathname === path;
  };
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [copytext, setCopyText] = useState("copy");
  const [toggle, settoggle] = useState(false);
  const [totalDeodStaked,settotalDeodStaked] = useState(0)
  const [circulatingNefiSupply,setcirculatingNefiSupply] = useState(0)
  const [totalSupply,settotalSupply] = useState(0)
  const togglesideBar = () => {
    settoggle(!toggle);
  };
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };
const loadData = async () => {
  const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
  const signer = newProvider.getSigner();
  const currentaccount = await signer.getAddress();
  const address = currentaccount.toLowerCase();
  setAddress(address)
  const nefiContract = new ethers.Contract(nefiToken, nefiTokenABI, signer)
  const totalDeodStaked = await nefiContract.totalDeodStaked()
      settotalDeodStaked(parseFloat(totalDeodStaked) / 10 ** 18)
      const circulatingNefiSupply =await nefiContract.circulatingNefiSupply()
      setcirculatingNefiSupply(parseFloat(circulatingNefiSupply) / 10 ** 18)
      const totalSupply =await nefiContract.totalSupply()
      settotalSupply(parseFloat(totalSupply) / 10 ** 18)
}
// console.log(totalSupply,'totalSupply')
  const handleCopy = () => {
    setCopyText("Copied!");
    let text = `https://stake.decentrawood.com/?${address}`;
    text.trim();
    navigator.clipboard.writeText(text);
  };

  setTimeout(() => {
    setCopyText("copy");
  }, 2000);
  useEffect(() => {
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setAddress(auth?.user?.address);
    }
  }, [auth]);

  const handleIsDisconnect = () => {
    setAuth({
      ...auth,
      user: null,
    });
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("auth");
    sessionStorage.clear();
    navigate("/");
  };
  useEffect(() => {
   loadData()
  }, [])
  
  return (
    // <>
    //   <Layout title={"Landing page"}>
    //     {loading && <Loading loading={loading} />}
    //     <div className="poke-bg">
    //       <div className="container py-4">
    //         <nav className="navbar bg-offcanwas-nav border-none shadow-none">
    //           <div className="container">
    //             <a
    //               className="navbar-brand text-light"
    //               data-bs-toggle="offcanvas"
    //               href="#offcanvasExample"
    //               role="button"
    //               aria-controls="offcanvasExample"
    //             >
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="25"
    //                 height="25"
    //                 fill="#fff"
    //                 class="bi bi-list"
    //                 viewBox="0 0 16 16"
    //               >
    //                 <path
    //                   fill-rule="evenodd"
    //                   d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    //                 />
    //               </svg>
    //             </a>
    //           </div>
    //         </nav>
    //       </div>
    //       {/* offcanwas */}

    //       <div
    //         class="offcanvas offcanvas-start offcanvas-start-cus"
    //         tabindex="-1"
    //         id="offcanvasExample"
    //         aria-labelledby="offcanvasExampleLabel"
    //       >
    //         <div class="offcanvas-header">
    //           <h5 class="offcanvas-title" id="offcanvasExampleLabel">
    //             Staking
    //           </h5>
    //           <button
    //             type="button"
    //             class="btn shadow-none"
    //             data-bs-dismiss="offcanvas"
    //             aria-label="Close"
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="25"
    //               height="25"
    //               fill="#fff"
    //               class="bi bi-x-circle"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
    //               <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
    //             </svg>
    //           </button>
    //         </div>
    //         <div className="offcanwas-body">
    //           <div className="sidebar-link">
    //             <ul className="side-ul">
    //               <li
    //                 className="dash-active"
    //                 onClick={() => navigate("/stakeone")}
    //               >
    //                 <Link>
    //                   {" "}
    //                   <i class="bi bi-speedometer2"></i>Dashboard
    //                 </Link>
    //               </li>
    //               <li onClick={() => navigate("/stakeone")}>
    //                 <Link to={"/stakeone"}>
    //                   <i class="bi bi-stack"></i> Stake 1
    //                 </Link>
    //               </li>
    //               <li onClick={() => navigate("/staketwo")}>
    //                 <Link>
    //                   {" "}
    //                   <i class="bi bi-stack"></i>Stake 2
    //                 </Link>
    //               </li>
    //               <li onClick={() => navigate("/stakethree")}>
    //                 <Link>
    //                   {" "}
    //                   <i class="bi bi-stack"></i>Stake 3
    //                 </Link>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>

    //       {/* end-canwas */}

    //       <div className="container py-3">
    //         <div className="row">
    //           <div className="col-12">
    //             <div className="copybox">
    //               <p>Refferal Link : </p>
    //               <div className="refbox">
    //                 <p>
    //                   https://stake.decentrawood.com/?
    //                   {start_and_end(address)}{" "}
    //                   <CopyToClipboard
    //                     text={`https://stake.decentrawood.com/?${address}`}
    //                     onCopy={handleCopy}
    //                   >
    //                     <button className="btn connect-btn">
    //                       {" "}
    //                       <i
    //                         class="fa-solid fa-copy mx-1"
    //                         style={{ fontSize: "20px" }}
    //                       ></i>
    //                       {copytext}
    //                     </button>
    //                   </CopyToClipboard>
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         {children}
    //       </div>
    //     </div>
    //   </Layout>
    // </>
    <>
      <div><div className={`sidebar ${toggle && "show"}`}>
        <div className="toggle-btn close" onClick={togglesideBar}>
          <FaTimes />
        </div>
        <a
          href="/"
          className="logo"
        ><img src={`/assets/logo-nefi.png`} style={{ width: '145px' }} />
          {/* <span className="fs-4"> Admin Deod</span> */}
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {
            sideLinks.map((data, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/${data}`}
                    activeClassName="active"
                    className={`nav-link link-body-emphasis ${isActive(`/${data}`) ? "active" : ""}`}
                  >
                    <svg className="bi pe-none me-2" width={16} height={16}>
                      <use xlinkHref="#speedometer2" />
                    </svg>
                    {data.charAt(0).toUpperCase() + data.slice(1)}
                  </Link>
                </li>
              )
            })
          }
        </ul>
        <hr />
      </div></div>
      <div className={`${content} ${toggle && "show"}`}>
        <div className="container">
          <div className="header-box">
            <div className="toggle-btn" onClick={togglesideBar}>
              <i className="bi bi-list text-white"></i>
            </div>
            {/* <div className="dropdown">
              <a
                href="#"
                className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >

                <img
                  src="https://github.com/mdo.png"
                  alt="error"
                  width={32}
                  height={32}
                  className="rounded-circle me-2"
                />
              </a>
              <ul className="dropdown-menu text-small shadow "  >
                <li>
                  <a className="dropdown-item" href="#">
                    New project...
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Profile
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Sign out
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="d-flex align-items-center">
              {auth?.user && (
                <>
                  <div className="dropdown ">
                    <div
                     style={{cursor:'pointer'}}
                      className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle position-relative  walled-ad"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                     <div>
                  <i class="bi bi-person-circle me-2" style={{width:'32px',height:'32px'}}></i>
                     </div>

                      <div
                        className="position-absolute"
                        style={{ top: `-15%`, right: `5%`, fontSize: "12px" }}
                      >
                        {auth?.user?.role === 1 && (
                          <span className="text-white bg-success rounded px-1">
                            Admin
                          </span>
                        )}
                      </div>
                      {start_and_end(auth?.user?.address)}
                    </div>
                    <ul className="dropdown-menu text-small shadow w-100 text-white">
                      {/* <li>
                        <a className="dropdown-item" href="#">
                          New project...
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Settings
                        </a>
                      </li> */}
                      {/* <li>
                        <Link to={"/profile"} className="dropdown-item">
                          Profile
                        </Link>
                      </li> */}
                       {!isActive('/contract1') && <li  style={{cursor:'pointer'}}>
                        <div
                          onClick={()=>navigate("/contract1")}
                          className="dropdown-item text-white"
                        >
                          Dashboard
                        </div>
                      </li>}  
                      <li  style={{cursor:'pointer'}}>
                        <div
                          onClick={handleIsDisconnect}
                          className="dropdown-item text-white"
                        >
                          Sign out
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>

          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Content;
