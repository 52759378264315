import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { ethers } from "ethers";
import Loading from "../LoaderPageSection/Loading";
import { nefiPro, nefiProABI, nefiToken, nefiTokenABI } from "../Abi/Content";
const Content = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [refferalAddress, setRefferalAddress] = useState("");
  const [userAddress, setUserAddress] = useState("");
       // const networkId = 137  //mainnet
       const networkId = 56  //testnet
       //    /*Switch to mainnet*/  const networkData = [
       //         {
       //             chainId: ethers.utils.hexlify(networkId),
       //             rpcUrls: ["https://polygon-rpc.com"],
       
       //             chainName: "Polygon Mainnet",
       //             nativeCurrency: {
       //                 name: "MATIC",
       //                 symbol: "MATIC", // 2-6 characters long
       //                 decimals: 18,
       //             },
       //             blockExplorerUrls: ["https://polygonscan.com/"],
       //         },
       //     ]
           /*Switch to Testnet*/  const networkData = [
               {
                   chainId: networkId,
                   rpcUrls: ["https://bsc-dataseed.binance.org/"],
       
                   chainName: "BNB Smart Chain",
                   nativeCurrency: {
                       name: "BNB",
                       symbol: "BNB", // 2-6 characters long
                       decimals: 18,
                   },
                   blockExplorerUrls: ["https:/https://bscscan.com/"],
               },
           ]
           const switchNetworks = async () => {
             
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: ethers.utils.hexlify(networkId) }],
                });
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: networkData
                        });
                    } catch (addError) {
                        // handle "add" error
                    }
                }
                // handle other "switch" errors
            }
    
        }
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 18) +
        "...." +
        address.substr(address.length - 18, address.length)
      );
    }
    return address;
  };
  const registerNefi = async (referrer) => {
   try {

    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer1 = provider.getSigner();
    const account = await signer1.getAddress()
    const network = await provider.getNetwork()
    console.log( network)
    if (network.chainId !== networkId) {
        await switchNetworks()
    }
    const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
    const signer = newProvider.getSigner();
    const nefiContract = new ethers.Contract(nefiPro,nefiProABI,signer)
    const register = await nefiContract.register(referrer)
    const reciept = await register.wait()
    console.log(reciept)
    return reciept
   } catch (error) {
    console.log(error)
    toast.error("Transaction Rejected")
    return error
   }
  }
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
    const reciept = await registerNefi(refferalAddress)
   if (reciept.status === 1) {
    const resp = await axios.post(`/users/register`, {
      accountAddress: userAddress,
      sponserAddress: refferalAddress,
      transactionHash:reciept.transactionHash
    });

    if (resp.status === 201) {
      if (resp?.data?.status === true) {
        toast.success(resp?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAuth({
          ...auth,
          user: null,
        });
        sessionStorage.removeItem("auth");
        navigate("/");
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
   }
   
     
    } catch (error) {
      if (error?.response?.status === 400) {
        setAuth({
          ...auth,
          user: null,
        });
        sessionStorage.removeItem("auth");
        navigate("/");
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const handleRegisterPro = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let checksponsorresp
      if (refferalAddress.toLowerCase() === `0x4172bdbf52e74570725866f4ab05e6288e8b10ee`){
        checksponsorresp=true
      }else {
        const checksponsorapi = `/nefi-pro-users/check-valid-reffer/${refferalAddress}`
         const resp2 = await axios(checksponsorapi)
         if (resp2.status ==200 ||resp2.status==201) {
           checksponsorresp = resp2.data.validUser 
         }
      }
      if (checksponsorresp) {
        const reciept = await registerNefi(refferalAddress)
        if (reciept.status === 1) {
         const resp = await axios.post(`/nefi-pro-users/register`, {
           accountAddress: userAddress,
           sponserAddress: refferalAddress,
           transactionHash:reciept.transactionHash
         });
     
         if (resp.status === 201) {
           if (resp?.data?.status === true) {
             toast.success(resp?.data?.message, {
               position: toast.POSITION.TOP_RIGHT,
             });
             setAuth({
               ...auth,
               user: null,
             });
             sessionStorage.removeItem("auth");
             navigate("/");
           } else {
             toast.error("Error", {
               position: toast.POSITION.TOP_RIGHT,
             });
           }
         } else {
           toast.error("Error", {
             position: toast.POSITION.TOP_RIGHT,
           });
         }
        }
      } else {
        toast.error('Invalid Referrer , Enter using valid Referral Link')
      }
   
   
     
    } catch (error) {
      if (error?.response?.status === 400) {
        setAuth({
          ...auth,
          user: null,
        });
        sessionStorage.removeItem("auth");
        navigate("/");
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  // const handleRegister = () => {
  //   alert("Server under maintenence ! Please Try again later")
  // }
  useEffect(() => {
    const defaultRefferalAddress = "0x4172bdbf52e74570725866f4ab05e6288e8b10ee";
    const refferal = auth?.user?.referralAddress;
    const isCheckAddress = refferal === "" ? defaultRefferalAddress : refferal;
    if (auth?.user?.address !== "" && isCheckAddress !== "") {
      setUserAddress(auth?.user?.address);
      setRefferalAddress(isCheckAddress);
    }
  }, [auth]);

  return (
    <Layout title={"Landing page"}>
      <div className="home-bg">
        {loading && <Loading loading={loading} />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="allotment-box">
                <form onSubmit={handleRegisterPro}>
                  {/* <form> */}
                  <div className="row">
                    <div className="col-12">
                      <h4
                        className="text-light float-end cus-cancelbtn"
                        onClick={() => navigate("/")}
                      >
                        X
                      </h4>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Connected Wallet
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={start_and_end(userAddress)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Referral Wallet
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={start_and_end(refferalAddress)}
                          disabled
                        />
                      </div>
                      {refferalAddress ===
                        "0x4172bdbf52e74570725866f4ab05e6288e8b10ee" && (
                        <span className="text-warning">
                          As your account is not referred by any user , You will
                          be registered with default referral address.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn connect-btn my-3">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
