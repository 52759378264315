import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../image/Logo.png";

import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../LoaderPageSection/Loading";
import RegisterPageSection from "../RegisterPageSection/Content";
import { StakeAbi, StakeAddress } from "../Abi/Content";
import { ethers } from "ethers";
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path) => {
    return location.pathname === path;
  };
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [auth, setAuth] = useAuth();

  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };
  const handleIsConnectApi = async (address) => {
    debugger
    try {
      const resp = await axios.post(`/users/login`, {
        accountAddress: address,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          const message = resp?.data?.message;
          const { accountAddress, sponserAddress, dateOfRegistration, userId } =
            resp?.data?.data;
          if (message === "Login successful") {
            const isAdmin = [
              // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
              "0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb",
            ];
            var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
            setAuth({
              ...auth,
              user: {
                userId,
                address: accountAddress,
                referralAddress: sponserAddress,
                role: role,
                token: resp?.data?.token,
                dateOfRegistration,
              },
            });
            sessionStorage.setItem(
              "auth",
              JSON.stringify({
                user: {
                  userId,
                  address: accountAddress,
                  referralAddress: sponserAddress,
                  role: role,
                  token: resp?.data?.data?.token,
                  dateOfRegistration,
                },
              })
            );
            sessionStorage.setItem('isAuthenticated', 'true');
            sessionStorage.setItem("myToggled", 0);
            sessionStorage.setItem('token',resp?.data?.data?.token,)
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp?.data?.token}`;
            let data = sessionStorage.getItem("auth");
            data = JSON.parse(data);
            const admin = data?.user?.role;
            if (admin === 1) {
              navigate("/admin-dashboard");
            } else {
              navigate("/contract1");
            }
          }
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        if (err_404 =="Invalid Account Address!"){
          toast.error('Invalid Account Address ,Please Register !');

        }else{
          toast.error(err_404);
        }
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleIsConnectApiPro = async (address) => {
    
    try {
      const resp = await axios.post(`/users/login`, {
        accountAddress: address,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          const message = resp?.data?.message;
          const { accountAddress, sponserAddress, dateOfRegistration, userId } =
            resp?.data?.data;
          if (message === "Login successful") {
            const isAdmin = [
              // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
              "0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb",
            ];
            var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
            setAuth({
              ...auth,
              user: {
                userId,
                address: accountAddress,
                referralAddress: sponserAddress,
                role: role,
                token2: resp?.data?.token,
                dateOfRegistration,
              },
            });
            sessionStorage.setItem(
              "auth",
              JSON.stringify({
                user: {
                  userId,
                  address: accountAddress,
                  referralAddress: sponserAddress,
                  role: role,
                  token2: resp?.data?.data?.token,
                  dateOfRegistration,
                },
              })
            );
            sessionStorage.setItem('isAuthenticated', 'true');
            sessionStorage.setItem("myToggled2", 0);
            sessionStorage.setItem('token',resp?.data?.data?.token,)
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp?.data?.token}`;
            let data = sessionStorage.getItem("auth");
            data = JSON.parse(data);
            const admin = data?.user?.role;
            if (admin === 1) {
              navigate("/admin-dashboard");
            } else {
                try {
                  const resp2 = await axios.post(`/nefi-pro-users/login`, {
                    accountAddress: address,
                  });
                  if (resp2.status === 200) {
                    sessionStorage.setItem('token2',resp2?.data?.data?.token,)
                    navigate("/contract2");
                  }
                } catch (error) { 
                  navigate("/contract1");
                }
                
            }
          }
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        if (err_404 =="Invalid Account Address!"){
          toast.error('Invalid Account Address ,Please Register !');

        }else{
          try {
            const resp = await axios.post(`/nefi-pro-users/login`, {
              accountAddress: address,
            });
            if (resp.status === 200) {
              if (resp?.data?.status === true) {
                const message = resp?.data?.message;
                const { accountAddress, sponserAddress, dateOfRegistration, userId } =
                  resp?.data?.data;
                if (message === "Login successful") {
                  const isAdmin = [
                    // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
                    "0xDD8703720aB03bf0738037a2d4F84b3bECf353Fb",
                  ];
                  var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
                  setAuth({
                    ...auth,
                    user: {
                      userId,
                      address: accountAddress,
                      referralAddress: sponserAddress,
                      role: role,
                      token2: resp?.data?.token,
                      dateOfRegistration,
                    },
                  });
                  sessionStorage.setItem(
                    "auth",
                    JSON.stringify({
                      user: {
                        userId,
                        address: accountAddress,
                        referralAddress: sponserAddress,
                        role: role,
                        token2: resp?.data?.data?.token,
                        dateOfRegistration,
                      },
                    })
                  );
                  sessionStorage.setItem('isAuthenticated', 'true');
                  sessionStorage.setItem("myToggled", 0);
                  sessionStorage.setItem('token2',resp?.data?.data?.token,)
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${resp?.data?.token}`;
                  let data = sessionStorage.getItem("auth");
                  data = JSON.parse(data);
                  const admin = data?.user?.role;
                  if (admin === 1) {
                    navigate("/admin-dashboard");
                  } else {
                    navigate("/contract2");
                  }
                }
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            if (error.response.status === 400) {
              var err_400 = error?.response?.data?.message;
              toast.error(err_400);
            } else if (error.response.status === 401) {
              const err_401 = error?.response?.data?.message;
              toast.error(err_401);
            } else if (error.response.status === 404) {
              const err_404 = error?.response?.data?.message;
              if (err_404 =="Invalid Account Address!"){
                toast.error('Invalid Account Address ,Please Register !');
      
              }else{
                toast.error(err_404);
              }
            } else if (error.response.status === 500) {
              const err_500 = error?.response?.data?.message;
              toast.error(err_500);
            } else {
              console.log("Error in Register", error);
              toast.error("something went wrong");
            }
          }
        }
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  // const handleIsConnectApi = async (address) => {
  //   try {
  //     const isAdmin = [
  //                   "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
  //                   // "0x65c0cb0e58d0a45d294bc0d1c37ee8c714e1372d",
  //                 ];
  //                 var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;

  //           setAuth({
  //             ...auth,
  //             user: {
  //               // userId,
  //               address: address,
  //               // referralAddress: sponserAddress,
  //               role: role,
  //               // token: resp?.data?.token,
  //               // dateOfRegistration,
  //             },
  //           });
  //           sessionStorage.setItem(
  //             "auth",
  //             JSON.stringify({
  //               user: {
  //                 // userId,
  //                 address: address,
  //                 // referralAddress: sponserAddress,
  //                 role: role,
  //                 // token: resp?.data?.token,
  //                 // dateOfRegistration,
  //               },
  //             })
  //           );
  //           sessionStorage.setItem("myToggled", 0);
  //           // axios.defaults.headers.common[
  //           //   "Authorization"
  //           // ] = `Bearer ${resp?.data?.token}`;
  //           // let data = sessionStorage.getItem("auth");
  //           // data = JSON.parse(data);
  //           const admin = "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2";
  //           if (admin === address) {
  //             navigate("/admin-sale1");
  //           } else {
  //             navigate("/sale1");
  //           } 
  //   } catch (error) {
  //     if (error.response.status === 400) {
  //       var err_400 = error?.response?.data?.message;
  //       toast.error(err_400);
  //     } else if (error.response.status === 401) {
  //       const err_401 = error?.response?.data?.message;
  //       toast.error(err_401);
  //     } else if (error.response.status === 404) {
  //       const err_404 = error?.response?.data?.message;
  //       toast.error(err_404);
  //     } else if (error.response.status === 500) {
  //       const err_500 = error?.response?.data?.message;
  //       toast.error(err_500);
  //     } else {
  //       console.log("Error in Register", error);
  //       toast.error("something went wrong");
  //     }
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const message = [
        "Welcome to NexDefi !",
        "Please sign this message to verify your identity.",
        "Please sign in!",
      ].join("\n\n");
      await signer.signMessage(message);
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAddress(address);
      await handleIsConnectApiPro(address);
    } catch (error) {
      console.log("wallet loagin Error", error);
    }
    setLoading(false);
  };

  // const handleSubmit = () => {

  // } 
  const handleIsDisconnect = () => {
    setAuth({
      ...auth,
      user: null,
    });
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("isAuthenticated");
    navigate("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        {loading && <Loading loading={loading} />}
        <div className="container">
          <Link className="navbar-brand">
            <img src={`/assets/logo-nefi.png`} alt="logo" style={{width:'150px'}}/>   
          </Link>
          <button
            className="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#fff"
              class="bi bi-list text-white"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            {!auth?.user ? (
              <form class="form-box my-3" onSubmit={handleSubmit}>
                <button className="btn connect-btn" type="submit">
                  Connect 
                </button>
              </form>
            ) : (
              <div className="d-flex align-items-center">
                {auth?.user && (
                  <>
                    <div className="dropdown "  style={{cursor:'pointer'}}>
                      <div
                        className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle position-relative  walled-ad"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                       <div>
                  <i class="bi bi-person-circle me-2" style={{width:'32px',height:'32px'}}></i>
                     </div>

                        <div
                          className="position-absolute"
                          style={{ top: `-15%`, right: `5%`, fontSize: "12px" }}
                        >
                          {auth?.user?.role === 1 && (
                            <span className="text-white bg-success rounded px-1">
                              Admin
                            </span>
                          )}
                        </div>
                        {start_and_end(auth?.user?.address)}
                      </div>
                      <ul className="dropdown-menu text-small shadow w-100 ">
                        {/* <li>
                          <a className="dropdown-item" href="#">
                            New project...
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Settings
                          </a>
                        </li> */}
                        {/* <li>
                          <Link to={"/profile"} className="dropdown-item">
                            Profile
                          </Link>
                        </li> */}
                         {!isActive('/contract1') && <li style={{cursor:'pointer'}}>
                        <div
                          onClick={()=>navigate("/contract1")}
                          className="dropdown-item"
                        >
                          Dashboard
                        </div>
                      </li>}
                        <li style={{cursor:'pointer'}}>
                          <div
                            onClick={handleIsDisconnect}
                            className="dropdown-item "
                          >
                            Sign out
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* <button className="d-flex btn walled-ad mx-2">
                        <div className="me-2">
                          <span className="user-icon">
                            <i class="bi bi-person-circle text-white mx-1"></i>
                          </span>
                          {auth?.user?.role === 0 ? (
                            <span className="text-white">User</span>
                          ) : (
                            <span className="text-white">Admin</span>
                          )}
                        </div>
                          <span className="user-icon">
                            <i class="bi bi-wallet2 "></i>
                          </span>
                          {start_and_end(auth?.user?.address)}
                        </button> */}
                  </>
                )}

                {/* <form class="form-box my-3" onSubmit={handleIsDisconnect}>
                        <button className="btn connect-btn" type="submit">
                          Disconnect
                        </button>
                      </form> */}
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
