import { Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
// import AdminDashbordPage from "./components/pages/AdminDashbordPage";
// import UserDashbordPage from "./components/pages/UserDashbordPage";
// import StackTokenPage from "./components/pages/StackTokenPage";
import RegisterPage from "./components/pages/RegisterPage";
import StakeOne from "./components/sections/UserPageSection/UserstakeOne";
import StakeTwo from "./components/sections/UserPageSection/UserDashboard2";
// import StakeTwo from "./components/sections/UserPageSection/UserstakeTwo";
// import StakeThree from "./components/sections/UserPageSection/UserstakeThree";
import AdminStakeOne from "./components/sections/AdminPageSection/AdminstackOne";
import ProtectedRoute from "./components/ProtectedRoute";
import axios from "axios";
// import AdminStakeTwo from "./components/sections/AdminPageSection/AdminstackTwo";
// import AdminStakeThree from "./components/sections/AdminPageSection/AdminstackThree";
// import StackTokenv2Page from "./components/pages/StackTokenv2Page";
// import StackTokenv3Page from "./components/pages/StackTokenv3Page";

function App() {

  // (function () {
  //   const token = sessionStorage.getItem("token");
  //   if (token) {
  //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //   }
  // })();

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      {/* <Route path="/admin-dashbord" element={<AdminDashbordPage />} /> */}
      <Route path="/admin-dashboard" element={<AdminStakeOne />} />
      {/* <Route path="/admin-sale2" element={<AdminStakeTwo />} /> */}
      {/* <Route path="/admin-stakethree" element={<AdminStakeThree />} /> */}
      {/* <Route path="/user-dashbord" element={<UserDashbordPage />} /> */}
      {/* <Route path="/stack-token" element={<StackTokenPage />} /> */}
      {/* <Route path="/stack-token-v2" element={<StackTokenv2Page />} /> */}
      {/* <Route path="/stack-token-v3" element={<StackTokenv3Page />} /> */}
      <Route path="/register" element={<RegisterPage />} />
      <Route element={<ProtectedRoute/>}>
      <Route path="/contract1" element={<StakeOne />} />
      <Route path="/contract2" element={<StakeTwo />} />
      </Route>
      {/* <Route path="/sale2" element={<StakeTwo />} /> */}
      {/* <Route path="/stakethree" element={<StakeThree />} /> */}
      <Route path="/register/:id" element={<RegisterPage />} />
    </Routes>
  );
}

export default App;
