import React from "react";
import { InfinitySpin, Triangle } from "react-loader-spinner";
import { useState, useEffect } from "react";
import "./styles.css";
const Loading = (props) => {
  const [loadingVisiblity, setLoadingVisiblity] = useState("none");
  useEffect(() => {
    if (props.loading) {
      setLoadingVisiblity("flex");
    } else {
      setLoadingVisiblity("none");
    }
  }, [props.loading]);
  return (
    <div className="loading" style={{ display: loadingVisiblity }}>
      <div className="text-center position-relative justify-content-center" style={{display:'flex',flexDirection:'column'}}>
        
        <Triangle
          height="85"
          width="85"
          color="#0eb7d1"
          ariaLabel="triangle-loading"
          wrapperStyle={{alignSelf:'center'}}
          wrapperClassName=""
          visible={true}
          />
      
        {/* <div> */}
          <div style={{ color: "#0dcaf0", fontWeight: "bold", marginTop: "31px" }}>
          {/* Please waiting transaction will be completed.... */}
           {!props.text ?'Please wait....':props.text}
        </div>
        <div className="" style={{ color: "#c5c519",  marginTop: "6px" ,left:'-10px'}}>
          Do not Refresh or Close the Page until transaction is completed
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Loading;
