import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvidr } from "./components/context/auth";
import axios from "axios";
const root = ReactDOM.createRoot(document.getElementById("root"));
// axios.defaults.baseURL = "http://192.168.1.53:5002";
axios.defaults.baseURL = "https://backend.nexdefi.ai";

// axios.defaults.baseURL = "https://stackingbackend.decentrawood.com";
// axios.defaults.baseURL = " https://tranquil-compassion-production.up.railway.app";
// axios.defaults.baseURL = " https://tranquil-compassion-production.up.railway.app";
// axios.defaults.baseURL = "http://localhost:4000";
root.render(
  <AuthProvidr>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </AuthProvidr>
);
reportWebVitals();
